<template>
  <van-form @submit="onSubmit">
    <van-cell-group :title="member.memberId ? '修改会员资料' : '完善个人信息，成为会员，享受更多特权'" inset>
      <van-field
        v-model="member.phone"
        name="phone"
        label="手机号"
        placeholder="请输入"
        disabled
      />
      <van-field
        v-model="member.name"
        name="name"
        label="姓名"
        placeholder="请输入"
      />
      <van-field name="gender" label="性别">
        <template #input>
          <van-radio-group v-model="member.gender" direction="horizontal">
            <van-radio name="SECRET">秘密</van-radio>
            <van-radio name="MALE">帅哥</van-radio>
            <van-radio name="FEMALE">美女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="member.birthday"
        readonly
        clickable
        name="birthday"
        label="生日"
        placeholder="请选择生日"
        @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          :formatter="formatter"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
          readonly
          clickable
          name="region"
          :model-value="member.region"
          label="所在区域"
          placeholder="点击选择"
          @click="showArea = true"
        />
        <van-popup v-model:show="showArea" position="bottom">
          <van-area
            :columns-num="3"
            :area-list="areaList"
            @confirm="onRegionConfirm"
            @cancel="showArea = false"
          />
        </van-popup>
        <van-field
          v-model="member.address"
          name="address"
          label="详细地址"
          placeholder="请输入"
        />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        {{ member.memberId ? '确认更新' : '成为会员' }}
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import dayjs from 'dayjs'
import Areas from '@/util/area'

export default {
  components: {
  },
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const Cookies = inject('Cookies')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      buyerId: Cookies.get('buyerId'),
      userId: Cookies.get('userId'),
      member: {
        gender: 'SECRET',
        region: '',
        address: '',
        remarks: ''
      },
      showPicker: false,
      currentDate: new Date(),
      minDate: new Date(1945, 0, 1),
      maxDate: new Date(2025, 10, 1),
      showArea: false,
      areaList: Areas
    })
    const formatter = (type, val) => {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
    }
    const onRegionConfirm = (values) => {
      state.member.region = values.map((item) => item.name).join('/')
      state.showArea = false
    }
    const onConfirm = (value) => {
      console.log(value)
      state.member.birthday = dayjs(value).format('YYYY-MM-DD')
      state.showPicker = false
    }
    const onSubmit = (values) => {
      Toast.loading({ duration: 0, forbidClick: true })
      console.log(values)
      const url = state.member.memberId ? '/member.update' : '/member.create'
      post(url, {
        memberId: state.member.memberId,
        userId: state.userId,
        name: values.name,
        gender: values.gender,
        birthday: values.birthday,
        region: values.region,
        address: values.address,
        storeId: state.storeId,
        remarks: state.member.remarks
      }).then(res => {
        Toast.clear()
        if (res.code === 0) {
          Toast.success()
          if (!state.member.memberId) {
            Cookies.set('memberId', res.data.id, { expires: 1 })
            if (typeof useRoute.query.redirect !== 'undefined') {
              useRouter.push(useRoute.query.redirect)
            } else {
              useRouter.go(-1)
            }
          }
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
      post('/buyer.get', {
        buyerId: state.buyerId
      }).then(res => {
        if (res.code === 0) {
          state.member.phone = res.data.user.phone
        } else {
          Toast(res.msg)
        }
      })
      post('/member.get', {
        userId: state.userId,
        storeId: state.storeId
      }).then(res => {
        if (res.code === 0) {
          if (res.data.memberId) {
            document.title = '会员资料'
          }
          state.member = { ...res.data }
          state.member.phone = res.data.user.phone
          if (res.data.avatar !== '') {
            state.uploader.push({
              url: res.data.avatar,
              isImage: true
            })
          }
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      onConfirm,
      formatter,
      onRegionConfirm
    }
  }
}
</script>

<style scoped>
</style>
